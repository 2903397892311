.list-container {
  overflow-y: auto;
  padding: 10px;
  max-height: 300px;
  border-radius: 5px;
}

.list-item {
  background-color: #f4f4f4;
  padding: 10px;
  margin-bottom: 8px;
  border: 1px solid #ccc;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.list-item > p {
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.delete-button {
  cursor: pointer;
  color: red;
  text-transform: uppercase;
  font-family: monospace;
  font-weight: 500;
  outline: none;
  border: 1px solid red;
  padding: 2px 6px;
  transition: 400ms;
  border-radius: 10px;
}
.prv-button {
  cursor: pointer;

  text-transform: uppercase;
  font-family: monospace;
  font-weight: 500;
  outline: none;
  border-radius: 10px;
  padding: 2px 6px;
  transition: 400ms;
  background-color: #f4f4f4;
  color: rgb(7, 127, 255);
  border: 1px solid rgb(7, 127, 255);
  padding: 2px 6px;
  transition: 400ms;
}

.prv-button:hover {
  background-color: rgb(7, 127, 255);
  color: white;
}

.delete-button:hover {
  background-color: red;
  color: white;
}

.list-item.dragging {
  opacity: 0.5;
}
