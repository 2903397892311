@page {
  size: 1280px 720px;
  margin: 0;
}
html,
body {
  padding: 0;
  margin: 0;
}
.page {
  page-break-after: always;
}

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

* {
  padding: 0;
  margin: 0;
}

p {
  margin-bottom: 0;
}

:root {
  --blue: #002f75;
  --table_cell_1: #e7f7ff;
  --table_cell_2: #d7f6eb;
}

@font-face {
  font-family: "Avenir Roman";
  src: url("../src/fonts/AvenirRoman.otf") format("opentype");
}

@font-face {
  font-family: "Avenir Medium";
  src: url("../src/fonts/AvenirMedium.otf") format("opentype");
}

@font-face {
  font-family: "Avenir Heavy";
  src: url("../src/fonts/AvenirHeavy.otf") format("opentype");
}

@font-face {
  font-family: "regular_regular";
  src: url("../src/fonts/Regular/Regular-Regular.otf") format("opentype");
}
@font-face {
  font-family: "regular_medium";
  src: url("../src/fonts/Regular/Regular-Medium.otf") format("opentype");
}
@font-face {
  font-family: "regular_semibold";
  src: url("../src/fonts/Regular/Regular-Semibold.otf") format("opentype");
}

.exportLabels {
  font-family: Regular_Semibold;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.02em;
  color: rgb(95, 97, 107);
  margin: 8px 0px 5px;
}
.mandatory {
  color: red;
  margin-right: 10px;
}
.BulkExportmodal .ant-modal-header {
  padding: 40px 16px 24px;
}
.BulkExportmodal .ant-modal-body {
  padding: 0 !important;
}
.BulkExportmodal .export-antd-container {
  padding: 24px !important;
}

.reportCheckContainer {
  margin-top: 10px;
}
.titlesContainer {
  max-height: 180px;
  overflow-y: scroll;
}
.titlesWrapper {
  display: flex;
  flex-direction: column;
}
.titlesContainer label {
  padding-bottom: 8px;
}
.titlesContainer .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0px !important;
}
.ant-modal.BulkExportmodal {
  width: 820px !important;
}
.bulkExportError {
  margin: 10px;
  color: rgb(192, 27, 27);
}
.lustTitlesBtn {
  margin-top: 15px;
  margin-left: 88%;
}
.footer {
  display: flex;
  height: 57px;
  padding: 0 1%;
  padding-right: 5px;
  justify-content: space-between;
  align-items: center;
}
.footer p {
  margin-bottom: 0;
}

/* commom css for slides for  */
h1 {
  font-family: "Avenir Heavy" !important;
  font-size: 26px;
  color: var(--blue);
  /* font-weight: 1200; */
}
h2 {
  font-family: "Avenir Heavy" !important;
  font-size: 26px;
  color: var(--blue);
  /* font-weight: 800; */
}
h3 {
  font-family: "Avenir Medium" !important;
  font-size: 18px;
  color: var(--blue);
  /* font-weight: 800; */
  text-transform: uppercase;
}
h4 {
  font-family: "Avenir Medium" !important;
  font-size: 18px;
  line-height: 28px;
  color: var(--blue);
  /* font-weight: 800; */
}
p {
  font-family: "Avenir Roman";
  font-size: 14px;
  color: var(--blue);
  font-weight: normal;
}

::-webkit-scrollbar {
  width: 7px !important;
  height: 7px !important;
  background-color: #fff;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
  border: 1px solid #fff;
  background-color: #fff;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #ddd;
  border: 1px solid #fff;
}
::-webkit-scrollbar-thumb:hover {
  background: rgb(185, 185, 185);
}
::-webkit-scrollbar-thumb:active {
  background-color: rgb(185, 185, 185);
}
.heading {
  color: #1f4986;
  font-family: "Avenir Heavy";
  font-style: normal;
  text-transform: uppercase;
  font-size: 26px;
  /* line-height: 50px; */
  /* font-weight: 800; */
}
.company_profile_heading {
  color: #1f4986;
  font-family: "Avenir Heavy";
  font-style: normal;
  font-size: 26px;
}
.subheading {
  color: #002f75;
  font-family: "Avenir Medium";
  font-style: normal;
  text-transform: uppercase;
  margin: 0px 0px 10px 0px;
  font-size: 16px;
  /* font-weight: 800; */
}
.container {
  height: 626px;
  padding: 25px 50px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}
h2.heading {
  margin-bottom: 10px;
}
li {
  line-height: 20px;
  color: #00a9ff;
  list-style-position: inside;
  margin-bottom: 10px;
  text-align: justify;
}
li span {
  color: #27518c;
  font-family: "Avenir";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
li::before {
  content: "";
}
li.ant-dropdown-menu-item {
  list-style: unset;
}
.tocanchorElement {
  text-transform: capitalize;
}
.sorceList {
  font-size: 11px;
  word-break: break-all;
}
hr {
  margin: 50px 0;
  border-top: 1px solid #dcdcdc;
}

.flex {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 50%;
  flex-direction: row;
  align-items: center;
}
/* end of common css */

.searchContainer {
  position: relative;
}
.searchContainer .searchResult {
  height: auto;
  min-height: 50px;
  background-color: ghostwhite;
  padding: 10px;
  overflow-x: scroll;
  margin-top: 10px;
}

.searchContainer .search-list-element {
  cursor: pointer;
}

/* Home slide */

.HomeSlide {
  position: relative;
  top: 0;
  overflow-y: none;
  max-height: 720px;
}

.HomeTitle {
  position: absolute;
  top: 230px;
  left: 104px;
  z-index: 3;
  color: white !important;
  word-break: keep-all;
  max-width: 450px;
  font-size: normal;
  font-family: "Avenir Roman";
  font-weight: bold;
  display: block;
  line-height: 50px !important;
}
.DomainContainer {
  position: absolute;
  top: 460px;
  left: 120px;
  z-index: 5;
  font-family: "Avenir";
  display: block;
}
.HomeSubHeading {
  color: white;
  font-family: "Avenir Roman";
  font-size: 18px;
}
.HomeSubHeading span {
  font-weight: bold;
}

.HomeBackground {
  image-rendering: -webkit-optimize-contrast;
}
tr.disabled-row {
  visibility: hidden;
  line-height: 0px;
  pointer-events: none;
}

.KeyDevelopment .SegmentDefinition td {
  font-family: "Avenir Roman";
  font-size: 13px !important;
  color: var(--blue);
  padding-top: 30px;
  padding-bottom: 30px;
}

.ProductsAndServicesTable td {
  font-family: "Avenir Roman";
  font-size: 13px !important;
  color: var(--blue);
  padding-top: 10px;
  padding-bottom: 10px;
}

thead th {
  font-family: "Avenir Heavy";
  font-size: 16px;
  color: var(--blue);
  white-space: nowrap;
  background: white !important;
  padding-bottom: 5px;
  font-weight: 800;
}

.KeyDevelopmentTable,
.SegmentDefinitionTable {
  border-spacing: 0px 18px;
  text-align: left;
}

.ProductsAndServicesTable {
  margin-bottom: -75px;
  border-spacing: 0px 18px;
  text-align: left;
}

.HideTableHeader th {
  color: transparent !important;
}

.SegmentDefinition,
.ProductsAndServices {
  padding-top: 40px;
  padding-left: 30px;
  padding-right: 30px;
}

/* MarketShareAnalysis */
.marketshareanalysis-main.main-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.marketshareanalysis-main li {
  margin-bottom: 10px;
}
.marketshareanalysis-main > .main-content-left {
  width: 50%;
}
.marketshareanalysis-main > .main-content-right {
  width: 45%;
}

/* RegulatoryFramework */
span.RegulatoryFrameworkBoxHeading {
  font-family: "Avenir Heavy";
  font-weight: 400;
  word-break: break-all;
  position: absolute;
  background-color: white;
  padding: 7px;
  top: -18px;
  left: 8px;
  z-index: 3;
  overflow-y: auto;
}
.heading.regulatoryFramework {
  margin-bottom: 0;
}

.SegmentDefinitionSubHeading {
  position: relative;
  font-family: "Avenir Heavy";
  font-weight: 100;
  color: var(--blue);
  font-size: 22px;
  left: 50%;
  transform: translate(-40%, 0);
  margin-top: 10px;
  margin-bottom: 0px;
  width: 400px;
}

.RegulatoryFrameworkSpaceForFooter {
  position: relative;
  display: block;
  top: 0px;
  height: 480px;
}

.regulatoryFrameworkCountry {
  text-align: center;
}
.regulatoryFrameworkCountry span {
  font-family: Avenir;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 28px;
  color: #002f75;
  margin-left: 10px;
}

.regulatoryFrameworkCountryv2 span {
  font-family: Avenir;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 28px;
  color: #002f75;
  margin-left: 10px;
}

.RegulatoryFrameworkBoxSlide {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
div.RegulatoryFrameworkBox {
  font-family: "Avenir Heavy";
  position: relative;
  width: 49%;
  margin: 7.89px 0;
  display: flex;
  flex-direction: column;
}

#RegulatoryFrameworkBox0 {
  border: 4px solid #189cde;
}
span#RegulatoryFrameworkBoxHeading0 {
  color: #189cde;
}

#RegulatoryFrameworkBox1 {
  border: 4px solid #9889f2;
}

span#RegulatoryFrameworkBoxHeading1 {
  color: #9889f2;
}

#RegulatoryFrameworkBox2 {
  border: 4px solid #ffac01;
}

span#RegulatoryFrameworkBoxHeading2 {
  color: #ffac01;
}

#RegulatoryFrameworkBox3 {
  border: 4px solid #2ac6a7;
}

span#RegulatoryFrameworkBoxHeading3 {
  color: #2ac6a7;
}

p.RegulatoryFramework_BulletPoints {
  font-family: "Avenir Roman";
  font-size: 14px;
  align-content: center;
  text-align: left;
  white-space: pre-line;
  overflow-wrap: break-word;
  position: relative;
  margin: 3%;
  color: var(--blue);
  line-height: 18px;
  display: flex;
  height: 100%;
}

sup a {
  text-decoration: none;
}

sup a:hover {
  text-decoration: underline;
}

h1 {
  margin-bottom: 10px;
}

.App {
  min-height: 100vh;
  overflow-y: auto;
}

.header {
  height: 10vh;
  min-height: 10vh;
  max-height: 10vh;
  background-color: #03b5b7;
  padding: 0 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-transform: uppercase;
}

.section-heading-1 {
  height: 15px;
  color: #fff;
  font-family: "Regular Bold";
  font-size: 12px;
  line-height: 15px;
  margin: 5px 0;
}

.section-heading-2 {
  height: 25px;
  color: #fff;
  font-family: "Regular Bold";
  font-size: 20px;
  line-height: 25px;
  margin: 5px 0;
}

.trends-title {
  color: #54b8bd;
  margin: 0 0 5px 0;
  padding: 0;
  font-size: 16px;
  line-height: 20px;
}

.main-content-left__barline {
  width: 90%;
  height: 40%;
}

.toc_updated_footer {
  background: #ddefff;
}

.report-name {
  color: #54b8bd;
  font-size: 12px;
  letter-spacing: 0.5px;
  line-height: 15px;
  text-transform: uppercase;
}

.page-number {
  font-size: 12px;
  letter-spacing: 0.5px;
  line-height: 15px;
}

table {
  margin: 0px auto;
  width: 100%;
}

table th {
  font-size: 16px;
}

table td {
  font-size: 14px;
}

caption {
  font-size: 1.6em;
  font-weight: 400;
  padding: 10px 0;
}

thead th {
  font-weight: 400;
  background: #03b5b7;
  color: #fff;
}

tr {
  background: #e7f7ff;
  border-bottom: 1px solid #fff;
  margin-bottom: 5px;
}

tr:nth-child(even) {
  background: #d7f6eb;
}

th,
td {
  text-align: left;
  padding: 20px;
  font-weight: 300;
}

th,
td {
  text-align: center;
}

tfoot tr {
  background: none;
}

tfoot td {
  padding: 10px 2px;
  font-size: 0.8em;
  font-style: italic;
  color: #8a97a0;
}

/* Type Overview */
.typeoverview-img {
  height: 50% !important;
  padding-bottom: 10px;
}

/* Region Overview */
.main-content-region-overview {
  height: 82vh;
  display: flex;
  align-items: center;
  max-width: 90%;
  flex: 0 0 90%;
  margin: 0px auto;
  padding-top: 25px;
  padding-bottom: 25px;
  flex-direction: column;
}

.region_overview_top_image {
  height: 50%;
  padding-bottom: 10px;
}

.region_overview_bottom_images {
  display: flex;
  justify-content: space-between;
  height: 50%;
  padding-top: 10px;
}

.region_overview_bottom_images img {
  max-width: 33%;
  flex: 0 0 33%;
}

.region_overview_bottom_images .pie:nth-child(2) {
  margin: 0px 10px 0px 10px !important;
}

/* Company Profile*/
.company-profile-left {
  display: flex;
  flex-direction: column;
  flex: 0 0 60%;
  max-width: 60%;
  padding-right: 10px;
}

.company-profile-right {
  display: flex;
  flex-direction: column;
  flex: 0 0 40%;
  max-width: 40%;
  padding-left: 10px;
}

.company-profile-bottom-section {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: auto;
}

.company-profile-bottom-section > div {
  padding: 20px 20px;
  width: calc(50% - 10px);
  margin: 10px 0;
}

.forecast-1 {
  background: #54b8bd;
}

.forecast-3 {
  background: #038cfc;
}

.forecast-2 {
  background: #cfc913;
}

.forecast-4 {
  background: #3904b4;
}

p {
  font-size: 14px;
  line-height: 25px;
}

.company-profile-right {
  padding: 30px 20px;
  background: #f2f2f2;
  position: relative;
}

.company-profile-right-top {
  height: 50%;
}

.company-profile-right-bottom {
  height: 50%;
}
.company-contact {
  margin: 20px;
}

.company-profile-logo {
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translateX(-50%);
}

.strategic-outlook,
.market-positioning {
  margin-bottom: 15px;
}

.forecast {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-family: regular_semibold;
}
.icons {
  width: 40px;
  height: 40px;
}

.forecast-content {
  width: 75%;
}

.contact-icon {
  width: 40px;
  height: 40px;
  background: #2a3d83;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
}

.company-contact-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

td {
  font-family: "Avenir Roman";
  font-size: 13px !important;
  color: var(--blue);
  margin-top: 200px;
}
th {
  font-style: normal;
  font-weight: 800 !important;
  font-size: 18px !important;
  line-height: 28px !important;

  color: #002f75 !important;
}

td.table-row-light {
  background-color: var(--table_cell_1);
}

td.table-row-dark {
  background-color: var(--table_cell_2);
}

thead th {
  font-family: "Avenir Roman";
  font-size: 12px;
  color: var(--blue);
  white-space: nowrap;
  background: white !important;
  padding-bottom: 5px;
  font-weight: 800;
}
table {
  border-spacing: 0px 18px;
  text-align: left;
}

div.ProductsAndServicesTable table {
  border-spacing: 0px 0px;
  text-align: left;
}

/* keystrategicMove css */
.strategicMovesContainer .strategicGraphs {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
}

.strategicMovesContainer .strategic-content {
  margin-top: 0px;
}

.keystrategic-list {
  margin: 20px 0;
}

.strategicMovesContainer .strategicGraphs .stackbar {
  width: 50%;
}
.strategicMovesContainer .strategicGraphs .pieGraph {
  width: 45%;
}

/* EXECUTIVE SUMMARY & KEY FINDINGS */
.excuSumry {
  height: 663px;
  padding: 30px 50px 0;
}

.excuSumry h2 {
  margin-bottom: 0px;
}

.excuSumry .excuSumryBoxes {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.excuSumry .excuSumryBoxes .excuSummeryBox {
  width: 32%;
  max-height: 276px;
  min-height: 276px;
  margin-top: 10px;
  padding: 20px;
}

.excuSumry .excuSumryBoxes .excuSummeryBox.graph {
  padding: 0;
}

.excuSummeryBox.graph p {
  display: flex;
  align-items: center;
  font-family: "Avenir Roman";
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #002f75;
}

.excuSummeryBox.graph p .cagr {
  font-family: "Avenir Roman";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  letter-spacing: 0.02em;
  margin-right: 8px;
  color: #1f4986;
}

.excuSummeryBox .flex {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.excuSummeryBox .flex .flex-item.content {
  width: 48%;
}

.excuSummeryBox .flex .flex-item.img {
  width: 40%;
  object-fit: contain;
}

.segment-block-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 32%;
  margin-top: 10px;
}

.segment-block {
  text-align: center;
  width: 49%;
  padding: 27px 10px 10px;
  height: 132px;
  margin-bottom: 10px;
}

.segment-block p {
  color: white;
  line-height: 22px;
}

.segment-block .segblk-value {
  font-size: 22px;
  font-family: "Avenir Heavy";
  margin-bottom: 3px;
  padding-top: 12px;
}

.segment-block_2 {
  text-align: center;
  display: flex;
  width: 49%;
  height: 60px;
  flex-direction: column;
  margin-bottom: 10px;
}

.segment-block_2 .segblk-value {
  font-size: 19px;
  font-family: "Avenir Heavy";
  margin-bottom: 3px;
  padding-top: 1px;
  color: white;
}

.segment-block_2 p {
  color: white;
}

.exeSumryHead {
  font-family: "Avenir Roman";
  font-weight: 800;
  font-size: 17px;
  line-height: 20px;
  font-style: normal;
  color: #1f4986;
  text-transform: uppercase;
}
.execSumSubHead {
  font-family: "Avenir Roman";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #002f75;
}

#login-box {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -10%);
  width: 300px;
  height: 500px;
}

#LogoutBtn {
  position: absolute;
  top: 10%;
  right: 5%;
  z-index: 20;
}
/* .ant-collapse > .ant-collapse-item {
  border-bottom: unset;
}
.ant-collapse {
  background-color: #ffff;
  border: unset;
}
.ant-collapse-content {
  border-top: unset;
}
.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header,
.panel-text {
  padding-left: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.65);
  line-height: 24px;
} */
/* .panel-text {
  padding: 12px 16px 0;
  padding-right: 40px;
  margin-bottom: 0;
  text-transform: capitalize;
}

.panel-text:hover {
  cursor: pointer;
}

.active_slide {
  color: #1890ff;
}
.ant-collapse-header {
  color: rgba(0, 0, 0, 0.65) !important;
  line-height: 24px;
  text-transform: capitalize;
}
.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  padding: 18px 16px 0;
  padding-right: 40px;
}
.ant-collapse-icon-position-right
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  right: 0px;
} */
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: unset;
}
.ant-menu-submenu-selected {
  color: #1890ff !important;
}

.ant-menu-title-content {
  /* color: white !important; */
  white-space: normal;
}

.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: #e6f7ff !important;
}
.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover {
  color: #1890ff;
}
.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  list-style-type: unset;
}
.KeyDevelopment.container {
  height: 625px;
}

/* VALUECHAIN CSS */
.font_design {
  display: flex;
  font-size: 14px;
  font-weight: bold;
  font-family: "Avenir Roman";
  color: #1f4986;
}

.font_design_architectural_coating_vc {
  display: flex;
  font-size: 13px;
  font-weight: bold;
  font-family: "Avenir Roman";
  color: #1f4986;
}

.font_design_proteins_vc {
  display: flex;
  font-size: 13px;
  font-weight: 700;
  line-height: 21px;
  font-family: "Avenir Roman";
  color: #1f4986;
}

/* maskcomponent css */
.maskBox {
  max-width: 500px;
  padding: 30px;
  box-shadow: rgb(221 221 221) 0px 0px 10px 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.MaskContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 105px);
}

.ant-table-wrapper::before {
  display: none;
  content: "";
}
.ant-table-measure-row {
  display: none !important;
}

.valuechain_title {
  margin-left: 55px;
  margin-top: 10px;
  margin-bottom: 0px !important;
}

/* Decision Maker */
.decision_maker {
  display: flex;
  justify-content: center;
}
.decision_maker img {
  width: 100%;
  z-index: -1;
  margin-top: -38px;
  margin-bottom: -56px;
}

/* Report Offers */
.reportoffer-container {
  display: flex;
  justify-content: center;
  height: 663px;
}
.reportoffer-container img {
  height: 663px;
  width: 100vw;
}

/* Research Methodology  */
.research-methodology-container {
  height: 626px;
}
.research-methodology-container img {
  height: 100%;
  width: 100%;
}

/* Static Slide */
.static-slide-container {
  height: 626px;
}
.static-slide-container img {
  height: 100%;
  width: 100%;
}

/* scopeof study static */
.scopestudy-static {
  display: flex;
  justify-content: center;
}
.scopestudy-static img {
  height: 626px;
  width: 100vw;
}
.ce-block__content {
  max-width: 100%;
}
.codex-editor__redactor {
  padding-bottom: 0 !important;
}
.ant-drawer-body {
  padding: 0;
}

/* Upload Sheet to Parse Excel  */

#SelectHub {
  width: 300px;
  top: 200px;
}

.ant-picker-decade-panel,
.ant-picker-year-panel,
.ant-picker-quarter-panel,
.ant-picker-month-panel,
.ant-picker-week-panel,
.ant-picker-date-panel,
.ant-picker-time-panel {
  width: 300px !important;
}

/* new templates */
.row {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
}

.column {
  flex: 50%;
  max-width: 48%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

/* Styles for landscape tables */
.LandscapeTable th,
.table_container th {
  background-color: #4472c4 !important;
  color: #ffffff !important;
  border: 1px solid white !important;
}

.LandscapeTable td {
  border: 1px solid white !important;
  color: black !important;
  font-size: 12px !important;
  padding: 3px 16px !important;
}

.LandscapeTable tr:nth-child(odd),
.table_container tr:nth-child(odd) {
  background-color: #cfd5ea;
  color: black !important;
}

.LandscapeTable tr:nth-child(even),
.table_container tr:nth-child(even) {
  background-color: #e8ebf5;
  color: black !important;
}

.table_container td {
  height: 50px;
  border: 1px solid white !important;
  color: black !important;
  font-size: 12px !important;
  padding: 3px 16px !important;
}

.custom-heading {
  cursor: default;
  font-style: normal;
  font-weight: normal;
  font-family: "Avenir Medium";
  font-size: 16px;
  text-anchor: start;
  color: rgb(0, 47, 117);
  text-transform: uppercase;
}

/* responsiveness */
@media only screen and (max-width: 960px) {
  .container {
    height: auto;
    padding: 25px 10px;
  }
  .drawerbtns {
    display: block;
    width: 90%;
    border-radius: 5px;
    margin-top: 20px;
    text-align: center;
    padding: 3px 20px;
    height: auto;
  }
  /* excuSumry */

  .excuSumry {
    height: auto;
    padding: 30px 10px 0;
  }
  .excuSumry .heading {
    font-size: 18px;
  }

  .excuSumry .excuSumryBoxes {
    flex-direction: column;
    margin-bottom: 60px;
  }
  .segment-block-container,
  .excuSumry .excuSumryBoxes .excuSummeryBox {
    width: 100%;
    height: auto;
  }

  /* keystrategicMove css */
  .strategicMovesContainer .strategicGraphs {
    flex-direction: column;
  }
  .strategicMovesContainer .strategicGraphs .stackbar,
  .strategicMovesContainer .strategicGraphs .pieGraph {
    width: 100%;
  }

  /* MarketShareAnalysis */
  .marketshareanalysis-main.main-container {
    height: auto;
    flex-direction: column;
  }
  .marketshareanalysis-main > .main-content-left,
  .marketshareanalysis-main > .main-content-right {
    width: 100%;
  }
  .ant-drawer-body li {
    line-height: 40px;
    color: unset;
    list-style-type: unset;
    list-style-position: unset;
    margin-bottom: unset;
    text-align: unset;
    display: list-item;
    text-align: -webkit-match-parent;
  }
}

.chatbox-input:focus {
  border: none;
  outline: none;
}

.invisible {
  display: none;
}

.visible {
  overflow: auto;
}

.select {
  border: 1px solid lightgrey;
  border-radius: 2px;
  width: 300px;
  height: 24px;
  padding: 0px 5px;
}

.select:hover {
  border: 1px solid #47cccf;
  cursor: pointer;
}

.option-container {
  border: 1px solid lightgrey;
  height: 250px;
  border-radius: 2px;
}
.option-container:hover {
  cursor: pointer;
}

.option {
  padding: 5px;
  color: black;
  font-size: 15px;
  vertical-align: center;
}

.selected-option {
  color: #47cccf;
}

.selected-option input:checked:before {
  background-color: green;
  margin-right: 20px;
}

.new-Container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.square {
  border-radius: 10px;
  border: 4px solid;
  justify-content: center;
  width: 100%;
  height: 95px;
  padding: 6%;
  cursor: pointer;
  display: flex;
  flex-direction: row;
}
.square2 {
  width: 100px;
  height: 100px;
  background-color: #00a9ff;
}
.gradient-text {
  font-size: 21px;
  background-image: linear-gradient(45deg, #ff6b6b, #2c8dbf, #ffd166);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: gradientAnimation 5s linear infinite;
  -webkit-animation: gradientAnimation 5s linear infinite; /* Safari */
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

#layout-buttons {
  border-radius: 5px;
  background: unset;
  font-family: "Avenir Roman";
  font-weight: 500;
  color: #1890ff;
}

#layout-buttons:hover {
  background: #1890ff !important;
  color: white !important;
}
.hoverEffect:hover {
  transition: 0.4s cubic-bezier(0.32, 0.02, 0, 0.98);
  transform: scale3d(1.02, 1.02, 1.02);
  margin: 0px 5px;
  text-decoration: underline;
  cursor: pointer;
}
.hoverEffectWund:hover {
  transition: 0.4s cubic-bezier(0.32, 0.02, 0, 0.98);
  transform: scale3d(1.02, 1.02, 1.02);
  margin: 0px 5px;

  cursor: pointer;
}
