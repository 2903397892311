.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 7px;
  grid-gap: 5px;
  gap: 7px;
  font-size: 12px;
  font-weight: 800;
  background: #fff;
  border-radius: 7px;
}

.options {
  width: 100%;
  text-align: center;
  border-radius: inherit;
  cursor: pointer;
}

.options:hover {
  background: #efefef;
}
.extra-options {
  position: absolute;
  background: rgb(245 245 245);
  padding: 10px;
  right: 100px;
  top: 10px;
  width: 101px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 0px #00000029;
  gap: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  cursor: pointer;
}

.extra-options div {
  width: 100%;
  text-align: center;
  border-radius: inherit;
  cursor: pointer;
}

.extra-options div:hover {
  background: #fff;
}

.chat {
  position: relative;
}

.chat-container {
  position: absolute;
  right: 0px;
}

.button-three-dots {
  background: none;
  border: none;
  transform: rotate(90deg);
  cursor: pointer;
}

.button-three-dots:hover {
  font-size: larger;
}

.button-opt {
  border: none;
  width: 100%;
  font-weight: bold;
  padding: 6px;
}

.button-opt:hover {
  background: #f1f1f1;
}

.attachment-btn {
  font-size: medium;
  box-shadow: 0px 0px 10px 0px #00000012;
  border-radius: 10px;
  padding: 4px;
  text-align: center;
  margin-top: 5px;
  cursor: pointer;
}

.attachment-btn:hover {
  background: #f1f1f1;
}
.emoption {
  display: flex;
  margin: 4px 8px;
  justify-content: flex-end;
  min-height: 22px;
  justify-content: flex-end;
  gap: 10px;
  position: relative;
}

.emoji:hover {
  font-size: x-large;
  cursor: pointer;
  margin-top: -6px;
}
